.react-terminal-line {
  font-family: 'JetBrainsMono-Regular';
}

.react-terminal,
.react-terminal-line {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.react-terminal-wrapper {
  background: transparent !important;
}

.react-terminal-wrapper:after,
.react-terminal-window-buttons {
  display: none !important;
}

.react-terminal-line.react-terminal-input.react-terminal-active-input {
  padding-bottom: 80px;
}

.react-terminal-wrapper {
  padding: 0!important;
}

.slick-list {
  padding: 0!important;
}

.terminal-flash {
  background-color: #fff !important;
  transition: all 0.2s ease-in-out;
}
